<template>
  <div>
    <b-card-code title="Update Margin " :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="typeSelect"
                    :state="typeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- From -->
            <b-col md="6">
              <b-form-group label="From" label-for="from">
                <validation-provider
                  #default="{ errors }"
                  name="to"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="From"
                    min="1"
                    type="number"
                    v-model="data.from"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- To -->
            <b-col md="6">
              <b-form-group label="To" label-for="to">
                <validation-provider
                  #default="{ errors }"
                  name="to"
                  rules="required|regex:^[0]*[1-9][0-9]*$"
                >
                  <b-form-input
                    id="to"
                    min="1"
                    type="number"
                    v-model="data.to"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Rate -->
            <b-col md="6">
              <b-form-group label="Rate" label-for="rate">
                <validation-provider
                  #default="{ errors }"
                  name="rate"
                  rules="required|regex:^([0-9]+)$"
                >
                  <b-form-input
                    id="rate"
                    min="1"
                    type="number"
                    v-model="data.rate"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- is_portal -->
            <b-col md="6">
              <b-form-group label="Is Portal ?" label-for="is_portal">
                <validation-provider
                  #default="{ errors }"
                  name="is_portal"
                  rules="required"
                >
                <b-form-checkbox switch v-model="data.is_portal">
                  <p v-if="data.is_portal"> Portal Price Rate </p>
                  <p v-else> In Store Price Rate</p>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";

import { heightTransition } from "@core/mixins/ui/transition";

import { required } from "@validations";
export default {
  components: {
  
    BCardCode,
  
  },
  
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      activeSelect: {
        value: "",
        text: "",
      },
      activeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "0",
          text: "False",
        },
        {
          value: "1",
          text: "True",
        },
      ],
      typeSelect: {
        value: "",
        text: "",
      },
      typeOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
        {
          value: "percentage",
          text: "Percentage",
        },
        {
          value: "fixed_price",
          text: "Fixed Price",
        },
      ],
      data: {
        active: "",
        from: "",
        is_portal: false,
        to: "",
        rate: "",
        type: "",
        service_type: null,
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
    };
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("vendors/price-rates/" + this.id)
      .then((result) => {
        const data = result.data.data;
        if (data.active == true) {
          this.activeSelect.text = "True";
          this.activeSelect.value = "1";
        } else {
          this.activeSelect.text = "False";
          this.activeSelect.value = "0";
        }
        if (data.type == "percentage") {
          this.typeSelect.text = "Percentage";
          this.typeSelect.value = "percentage";
        } else {
          this.typeSelect.text = "Fixed Price";
          this.typeSelect.value = "fixed_price";
        }
        this.data.from = data.from;
        this.data.to = data.to;
        this.data.rate = data.rate;
        this.data.service_type = data.service_type;
        this.data.is_portal = data.is_portal;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value;
          this.data.type = this.typeSelect.value;
          axios
            .put("vendors/price-rates/" + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>
